@import '../../../styles/customMediaQueries.css';

.label,
.labelSelected {
  display: inline-block;
  width: auto;
  height: var(--searchPageHeaderPanelHeight);
  min-height: var(--searchPageHeaderPanelHeight);
  width: 100%;
  min-width: 120px;

  padding: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Flex layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Font */
  composes: searchPageFontStyles from global;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: left;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    padding: 0 16px;
    background-color: var(--colorWhite);
  }

  @media (min-width: 900px) {
    min-width: 140px;
  }

  @media (--viewportLarge) {
    min-width: 160px;
  }

  @media (min-width: 1280px) {
    min-width: 200px;
  }
}

.label {
  /* Button borders */
  border: 0;
}

.labelSelected {
  border: 0;
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
