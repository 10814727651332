@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: inline;

  /* Position & scroll */
  position: relative;

  /* Style */
  border-top: 1px solid var(--marketplaceColor);
  border-bottom: 1px solid var(--marketplaceColor);
  width: 100%;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.rootCompany {
  display: none;
}

.searchLabel {
  display: none;
}

.searchLabel {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;

  /* Layout */
  align-items: center;
  justify-content: center;

  /* Size */
  height: var(--searchPageHeaderPanelHeight);
  min-height: var(--searchPageHeaderPanelHeight);
  padding: 0 36px;
}
