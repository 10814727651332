@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding: 0;
  margin: 0;

  & form {
    min-width: 120px;
    flex: 1;
  }
}

.filterFormContent {
  display: flex;
  align-items: center;
  position: relative;
}

.clearButton {
  composes: h5 from global;
  font-size: 18px;
  margin: 0;
  padding: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  text-transform: uppercase;

  /* Position */
  position: absolute;
  right: 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.plain {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.submitButton {
  /* Reset */
  border: 0;
  margin: 0;
  padding: 0;

  /* Style */
  background-color: var(--marketplaceColor);

  /* Size */
  height: var(--searchPageHeaderPanelHeight);
  width: 100px;

  /* Effect */
  cursor: pointer;

  @media (--viewportLarge) {
    width: 144px;
  }

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }

  &:disabled {
    cursor: no-drop;

    &:hover {
      background-color: var(--marketplaceColor);
    }
  }
}

.searchIcon {
  stroke: var(--colorWhite);
  transform: rotate(270deg);
}
