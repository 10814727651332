@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;
  overflow: unset !important;

  /* Size */
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listingCardsCollapsed {
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCardsTalent {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  border-bottom: 1px solid var(--marketplaceColor);
  overflow: unset !important;

  /* Size */
  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(1, 1fr);
    border-bottom: 0;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listingCardsTalentCollapsed {
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.listingCard {
  width: 100%;
  min-width: 0;
  height: 100%;
}

.pagination {
  padding: 12px 24px;

  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.inquirySubmitButtonWrapper {
  margin-top: 32px;
}
