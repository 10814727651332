@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  composes: h4 from global;
  margin: 0;
  padding: 0;
  border: none;
}

.fieldPlainInput {
  /* Font */
  composes: searchPageFontStyles from global;
  text-transform: initial;

  /* Layout */
  height: var(--searchPageHeaderPanelHeight);
  border: 0;

  /* Padding */
  padding-left: 16px;
  padding-right: 32px;

  &:hover,
  &:focus {
    border: 0;
  }
}
