@import '../../../styles/customMediaQueries.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel,
.menuLabelSelected {
  display: inline-block;
  width: auto;
  height: var(--searchPageHeaderPanelHeight);
  min-height: var(--searchPageHeaderPanelHeight);
  width: 100%;
  min-width: 120px;

  padding: 0 16px;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Flex layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Font */
  composes: searchPageFontStyles from global;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: left;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (min-width: 900px) {
    min-width: 140px;
  }

  @media (--viewportLarge) {
    min-width: 180px;
  }

  @media (min-width: 1280px) {
    min-width: 220px;
  }
}

.menuLabel {
  /* Button borders */
  border: 0;
  background-color: var(--colorWhite);
}

.menuLabelSelected {
  border: 0;
}

.menuContent {
  margin-top: 6px;
  padding-top: 20px;
  min-width: 300px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--marketplaceColor);
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--marketplaceColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 24px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 24px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--colorGrey700);
    transition: width var(--transitionStyleButton);
  }
}

.menuItemCheckbox {
  border: none;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
}

.radioButton{
  color: white;
  padding: 6px;
  margin: 2px;
  border: 1px solid grey;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
}

.radioButtonSelected{
  padding: 3px;
}

.radioSelected{
  background-color: var(--marketplaceColor);
  padding: 3px;
  border-radius: inherit;
}
