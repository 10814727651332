@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed !important;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-grow: 1;

    position: relative;
    padding-top: calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder));
    min-height: calc(
      100vh - calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder))
    );
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 70%;
  }
}

.error {
  color: var(--colorFail);

  margin-top: 0;
  margin-bottom: 0;
  padding: 24px;
}

.filtersMenuLabel {
  min-width: 120px;

  @media (min-width: 1280px) {
    min-width: 180px;
  }
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  /* Position */
  position: fixed;
  top: var(--topbarHeightDesktop);
  z-index: var(--zIndexTopbar);
}

.searchFiltersMobileMap {
  padding: 0 16px;
  border-bottom: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForMapVariant {
  composes: listings;

  padding-bottom: 52px;
  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 30%;
  }
}

.mapWrapper {
  position: relative;

  /* Size */
  width: 100vw;
  height: 100vh;
  max-height: 375px;
  border-bottom: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(
      100vh - calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder))
    );
    max-height: calc(
      100vh - calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder))
    );
    border-bottom: 0;
  }

  @media (--viewportLarge) {
    width: 30vw;
  }
}

.map {
  width: 100vw;
  height: 100%;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder));
    left: 0;
    border-right: 1px solid var(--marketplaceColor);

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(
      100vh - calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder))
    );
  }

  @media (--viewportLarge) {
    width: 30vw;
  }
}

.openMapButton,
.closeMapButton {
  /* Position */
  position: absolute;
  z-index: calc(var(--zIndexTopbar) - 1);

  /* Font */
  font-family: var(--fontFamily);
  font-size: 14px;
  text-transform: capitalize;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  /* Size */
  width: fit-content;
  min-height: 36px;
  height: 36px;
  padding: 0 9px;

  /* Layout */
  border-radius: 9999px;

  @media (--viewportMedium) {
    position: fixed;
  }
}

.openMapButton {
  top: calc(var(--topbarHeight) + 43px + 43px + 16px);
  right: 15px;

  @media (--viewportMedium) {
    top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 16px
    );
    left: 16px;
    right: unset;
  }

  &:hover,
  &:focus {
    & .openMapButtonIcon path {
      fill: var(--colorWhite);
    }
  }
}

.openMapButtonNoResults {
  bottom: 15px;
  top: unset;

  @media (--viewportMedium) {
    top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 16px
    );
    bottom: unset;
    left: unset;
    right: 16px;
  }
}

.openMapButtonIcon path {
  transition: var(--transitionStyleButton);
}

.closeMapButton {
  top: calc(var(--topbarHeight) + 43px + 12px);
  right: 15px;

  @media (--viewportMedium) {
    top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 15px
    );
    left: calc(50vw - calc(110px + 15px));
    right: unset;
  }

  @media (--viewportLarge) {
    left: calc(30vw - calc(110px + 15px));
  }
}

.closeMapIcon {
  stroke: none;
}

/* .locationSearch {
  position: absolute;
  top: calc(var(--topbarHeight) + 43px + 12px);
  left: 15px;
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    position: fixed;
   
    top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 15px
    );
    left: calc(50vw - calc(110px + 15px));
    right: unset;
  }
} */

.locationSearch {
  /* Position */
  position: absolute;
  z-index: calc(var(--zIndexTopbar) - 1);

  /* Font */
  font-family: var(--fontFamily);
  font-size: 14px;
  text-transform: capitalize;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  /* Size */
  width: fit-content;
  min-height: 36px;
  height: 36px;
  padding: 0 9px;

  /* Layout */
  border-radius: 9999px;

  @media (--viewportMedium) {
    position: fixed;
    width: 200px;
  }

  @media (--viewportLarge) {
    width: 120px;
  }

  @media (--viewportMLarge) {
    width: 200px;
  }

}

.locationSearch {
  /* top: calc(var(--topbarHeight) + 43px + 12px); */
   top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 55px
    );
  right: 4px;

  @media (--viewportMedium) {

    top: calc(
      calc(var(--topbarHeightDesktop) + var(--searchPageHeaderPanelHeightWithBorder)) + 15px
    );
  left: 56px;

  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;

  @media (--viewportMedium) {
    display: none;
  }
}

.locationFilter {
  max-width: 140px;
  height: 42px !important;

  @media (--viewportLarge) {
    max-width: 180px;
  }

  @media (min-width: 1280px) {
    max-width: 220px;
  }
}

.locationFilterInput {
  /* Font */
  composes: searchPageFontStyles from global;
  text-transform: initial;

  /* Layout */
  height: var(--searchPageHeaderPanelHeight);
  border: 0;

  /* Padding */
  padding-left: 16px;
  padding-right: 32px;

  &:hover,
  &:focus {
    border: 0;
  }
}

.locationFilterPredictions {
  position: absolute;
  width: 100%;
  top: 42px;
  left: -1px;
  background-color: var(--marketplaceColor);
  box-shadow: var(--boxShadowPopup);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  min-width: 300px;
  max-width: 434px;
}
