@import '../../../styles/customMediaQueries.css';

.root {
  display: inline-block;
  width: auto;
  height: var(--searchPageHeaderPanelHeight);
  min-height: var(--searchPageHeaderPanelHeight);
  width: 100%;
  min-width: 120px;

  padding: 0 16px;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Flex layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Button borders */
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  background-color: var(--colorWhite);

  /* Width */
  width: fit-content;

  /* Font */
  composes: searchPageFontStyles from global;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: left;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }
}
