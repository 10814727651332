@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px;

  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 24px;
  }
}

.resetAllFiltersButton {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  text-decoration: underline;
  text-underline-position: under;

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
}
